<template>
	<Page :name="'equipe'" @loaded="onLoaded">
		<template v-slot:content>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<h1>{{ data.Titre }}</h1>
						<section :id="'equipe-' + index" v-for="(specialite, index) in data.specialites" :key="'specialite-' + index" class="equipe-item py-4 py-lg-5 overflow-hidden border-bottom">
							<div class="col-12 col-md-5 d-flex flex-column justify-content-center">
								<ButtonPicto :icon-name="specialite.Nom_picto" variant="secondary" :linkto="{ name: 'specialite', params: { id: specialite.id } }"></ButtonPicto>
								<h2 class="text-uppercase text-secondary fs-3 my-4">{{ specialite.Titre }}</h2>
								<router-link :to="{ name: 'specialite', params: { id: specialite.id } }" class="btn btn-secondary btn-more mb-4 mb-md-0">En savoir plus</router-link>
							</div>
							<div class="col-12 col-md-7">
								<div class="col-12 col-md-8 mx-auto overflow-hidden">
									<div class="slider">
										<div class="swiper-wrapper">
											<div class="swiper-slide" v-for="(slide, index) in specialite.slider" :key="'slide-' + index">
												<Image :img="slide" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</template>
	</Page>
</template>

<script>
import Swiper, { Autoplay, Navigation } from "swiper";
Swiper.use([Autoplay, Navigation]);
import "../../../node_modules/swiper/swiper.min.css";
export default {
	name: "Equipe",
	data() {
		return {
			data: null,
			sliderSettings: {
				autoplay: true,
				centerSlides: true,
				loop: true,
			},
		};
	},

	mounted() {
		//console.log('news mounted',  this.data)
	},
	methods: {
		onLoaded(data) {
			this.data = data;
			//console.log('page équipe', this.data, this.data.specialites)
			this.$nextTick(() => {
				setTimeout(() => {
					this.initSliders();
				}, 100);
			});
		},
		initSliders() {
			for (let id in this.data.specialites) {
				let slider = new Swiper("#equipe-" + id + " .slider", {...this.sliderSettings, ...{autoplay : this.data.specialites[id].slider.length > 1}});
			}
		},
	},
};
</script>

<style lang="scss">
.page-equipe {
	section.equipe-item {
		display: flex;
		flex-direction: column;

		.btn-more {
			font-size: 0.8rem;
			text-transform: uppercase;
		}
		& > div:first-of-type {
			align-items: center;
		}
	}
	.slider {
		//height: 200px;
		aspect-ratio: 1.49;

		.swiper-slide img{
			min-width: 100%;
    min-height: 100%;
    width: 100%;
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
		}
	}
}

@media (min-width: 768px) {
	.page-equipe {
		section.equipe-item {
			flex-direction: row;
			& > div:first-of-type {
				align-items: flex-end;
			}
			&:nth-child(odd) {
				flex-direction: row-reverse;
				& > div:first-of-type {
					align-items: flex-start;
				}
			}
		}
	}
}
</style>
