<template>
	<Page :name="'page-praticiens'" @loaded="onLoaded">
		<template v-slot:content>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<h1><i :class="'fas fa-' + data.specialite.Nom_picto"></i>{{ data.Titre }}</h1>
						<div class="html-content mb-4" v-html="$mdToHtml(data.text)"></div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 text-center">
					<router-link :to="{ name: 'rdv' }" class="btn btn-primary text-uppercase mx-auto my-5">Prendre rendez-vous</router-link>
				</div>
			</div>
		</template>
	</Page>
</template>

<script>
export default {
	name: "ProMenu",
	data() {
		return {
			data: null,
			//praticiens: null,
		};
	},
	computed() {},

	mounted() {
		console.log("pro mounted", this.data, this.$route.params.id);
	},
	methods: {
		onLoaded(data) {
			console.log('data in pro', data)
			this.data = data
			
			
		},
	},
};
</script>

<style lang="scss">
.page-praticien {
	h1 svg {
		border-radius: 100%;
		border: 1px solid #e71f73;
		//font-size: 80%;
		padding: 0.4rem;
		margin-right: 0.5rem;
	}
}
</style>
