<template>
	<Page :name="'specialites'" @loaded="onLoaded">
		<template v-slot:content>
			<div v-if="data && !hasOnlyOnePraticien" class="container">
				<div class="row">
					<div class="col-12">
						<h1><i :class="'fas fa-' + data.Nom_picto"></i>{{ data.Titre }}</h1>
					</div>
					<div class="col-12 text-center d-flex flex-column">
						<router-link v-for="page, index in pages" :key="index" :to="{ name: 'praticien', params: {id : page.id} }" class="btn btn-primary text-uppercase mx-auto my-2">{{ page.name }}</router-link>
					</div>
				</div>
			</div>
			<!--router-view></!--router-view-->
		</template>
	</Page>
</template>

<script>
export default {
	name: "specialite",
	data() {
		return {
			data: null,
			
		};
	},
	computed: {
		hasOnlyOnePraticien(){
			return this.pages.length == 1
		},
		pages(){
			return this.data?.page_praticiens
		}
	 },

	mounted() {
		console.log("specialité mounted", this.data);
	},
	methods: {
		onLoaded(data) {
					console.log(' specialite getted', data)
					
					this.data = data
					console.log(" specialite", this.data, this.data.page_praticiens);
					
					if (this.hasOnlyOnePraticien) {
						console.log('GO TO PAGE PRATICIEN DIRECTLY')
						this.$router.push({ name: 'praticien', params: { id: this.data.page_praticiens[0].id } })
					}


		},
	},
};
</script>

<style lang="scss">
.page-specialite {
	h1 svg {
		width: 1em;
		border-radius: 100%;
		border: 1px solid #e71f73;
		//font-size: 80%;
		padding: 0.4rem;
		margin-right: 0.5rem;
	}
}
</style>
