/**
 * ROUTING FOR MSP
 */

//import router elements
 import { createRouter, createWebHistory } from 'vue-router'


// import pages components : 
import Home from '../pages/home.vue';
import Maintenance from '../pages/maintenance.vue';
import Equipe from '../pages/equipe.vue';
import Infos from '../pages/infos.vue';
import MSP from '../pages/msp.vue';
import News from '../pages/news.vue';
import NewsDetail from '../pages/news-detail.vue';
import Rdv from '../pages/rdv.vue';
import Praticien from '../pages/praticien.vue';
import Specialite from '../pages/specialite.vue';
import Urgence from '../pages/urgence.vue';

import store from '../store/store';

//define routes
 const routes = [

	// Home
	{
		path : '/',
		component : Home,
		redirect : '/accueil',
		
	},
	{
		path : '/accueil',
		name : 'home',
		component : Home,
		
		
	},
	

	{
		path : '/equipe',
		name: 'equipe',
		component : Equipe
	},
	{
		path : '/infos-pratiques',
		name : 'infos',
		component : Infos
	},
	{
		path : '/la-msp',
		name : 'msp',
		component : MSP
	},
	{
		path : '/actualites',
		name : 'news',
		component : News
	},
	{
		path : '/actualites/:id',
		name : 'news-detail',
		component : NewsDetail
	},
	{
		path : '/rendez-vous',
		name : 'rdv',
		component : Rdv
	},
	
	{
		path : '/specialite/:id',
		name : 'specialite',
		component : Specialite,
		children: [
			
		]
	},
	{
		path : '/praticien/:id',
		name : 'praticien',
		component : Praticien
	},
	{
		path : '/urgence',
		name : 'urgence',
		component : Urgence
	},


	//page maintenance
	{
		path : '/maintenance',
		name: 'maintenance',
		component : Maintenance,
		beforeEnter: (to, from, next) => {
			//redirife sur la home si paramètre offf
			if(store.getters.getMaintenanceMode == false) {
				next('/');
			} else {
				next();
			}
		}
	},

	
]


//define router
export const router =  createRouter({
	history : createWebHistory(),
	routes : routes
})
