<template>
	<div id="msp-wrapper" :class="'container-fluid page-' + $route.name">
		<Spinner v-if="!isLoaded" />

		<Header v-if="!$store.getters.getMaintenanceMode && isLoaded"></Header>

		<main v-if="isLoaded" class="row">
			<router-view></router-view>
		</main>

		<Footer v-if="!$store.getters.getMaintenanceMode && isLoaded"></Footer>
	</div>
</template>

<script>
import CONFIG from "../config.json";
import Header from "./components/header/header.vue";
import Footer from "./components/footer/footer.vue";

export default {
	components: {
		Header,
		Footer,
	},
	data() {
		return {
			config: CONFIG[process.env.NODE_ENV],
			baseUrl: CONFIG[process.env.NODE_ENV].adminUrl,
			isLoaded: false,
		};
	},
	beforeMount() {
		console.log('BEFORE MOUNT')
		//console.log('this config ', this.config, this.baseUrl)
		this.$apiGetData("coords")
			.then((coords) => {
				//console.log("coord are ", coords);

				this.$store.commit("setCoords", coords);

				this.$apiGetData("parametres")
					.then((param) => {
						//console.log("paremet are ", param, param.maintenance);
						if (param.maintenance) {
							console.warn("!!! MAINTENANCE MODE !!!");
							this.$store.commit("setParameters", param);
							this.$store.commit("setMaintenanceMode", true);
							this.$router.push("maintenance");
						}
						this.isLoaded = true;
					})
					.catch((err) => {
						console.warn(err);
					});
			})
			.catch((err) => {
				console.warn(err);
			});
	},
	created() {},
	methods: {
		sortDate(array){
			return array.slice().sort((a, b)=>{
				return new Date(b.updated_at) - new Date(a.updated_at)
			})
		}
	},
};
</script>

<style lang="scss">
:root {
	--rose: #e71d73;
	--blue: #1d71b8;
	--green: #3aaa35;
	--yellow: #ffd91a;
	--font-L: "Lato", sans-serif;
	--font-M: "Montserrat Alternates", sans-serif;
}

body,
html {
	height: 100%;
	width: 100%;
	text-align: left;
	font-family: var(--font-L);
}

#msp,
#msp-wrapper {
	width: 100%;
	height: 100%;


}

#msp-wrapper main{
	//width: 100%;
	min-height: 100%;
	//margin: auto;
	//overflow-x:hidden;
}
</style>
