
import axios from 'axios';
import backRoutes from '../routing/back-routes'

export default {
	install: (app, options) => {
	  

		let apiGetData = (route, id=null) => {
			return new Promise((res, rej)=>{
				console.log('[$apiGetData] get ', route, id)
				let backRoute = id ? backRoutes[route] + '/'+id : backRoutes[route];
				console.log('[$apiGetData] go to BACKEND route ', backRoute)
				axios.get(backRoute)
				.then(reponse=>{
					console.log('[$apiGetData]  succeed ',reponse.data)
					res(reponse.data)
				})
				.catch(err=>{
					console.warn('[$apiGetData]  error ', err)
					rej(err)
				})
			})
			
		  };


		//available as this.$apiGetData
	  app.config.globalProperties.$apiGetData = apiGetData;
		  
	}
  }